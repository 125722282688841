<template>
  <v-layout wrap justify-center>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 sm8 md8 lg8 py-6>
      <!------------------------------------------------------------------------------------------------------------------------------->
      <v-card outlined>
        <v-layout wrap justify-center class="pa-md-8 gradientCard" v-if="user1">
          <v-flex xs12 text-right align-self-end>
            <!-- <deleteStudent style="cursor: pointer" /> -->
          </v-flex>
          <v-flex xs12 align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs12 text-right>
                      <v-icon @click="$router.go(-1)" title="Back"
                    style="cursor: pointer;color:#766BC0"
                    >mdi-arrow-left</v-icon
                  >
                    </v-flex>
              <v-flex xs12 text-center>
                <v-avatar size="96px" radius="10px">
                  <img
                    src="@/assets/images/Ellipse23.png"
                    alt="John"
                    class="pa-1"
                  />
                </v-avatar>
              </v-flex>
              <v-flex xs12 pb-2 text-center>
                <span class="nssbold" style="font-size: 18px; color: #ffffff">
                  {{ name }}
                </span>
              </v-flex>
              <v-flex xs12 text-center>
                <v-chip
                  elevation="2"
                  rounded
                  color="#766BC0"
                  class="white--text"
                  style="font-weight: 500; font-size: 14px"
                  v-if="user1.studentclass"
                >
                  <span v-if="user1.studentclass.className">
                    {{ user1.studentclass.className }}
                  </span>
                </v-chip>
                <v-chip
                  dark
                  elevation="2"
                  class="ml-2"
                  rounded
                  outlined
                  style="font-weight: 400; font-size: 14px"
                  >Adm. No.: {{ admissionNo }}</v-chip
                >
                <v-chip
                  dark
                  elevation="2"
                  class="ml-2 my-2"
                  rounded
                  outlined
                  style="font-weight: 400; font-size: 14px"
                  v-if="user1.school"
                  >School: {{ user1.school.SchoolName }}</v-chip
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
      <v-card class="mx-auto pa-6" max-width="1087px" align="center" outlined>
        <!--------------------------------------------edit----------------------------------------->

        <!-- <v-layout wrap>
          <v-flex xs12 text-right>
            <v-icon>mdi-edit</v-icon>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on"> <v-icon>mdi-circle-edit-outline</v-icon> Edit</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Admission.No: {{ user.admissionNo }}</span>
                </v-card-title>
                <v-card-text>
                    <v-layout wrap>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="user.fname" label="First name"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6 pl-4>
                        <v-text-field v-model="user.lname" label="Last name"></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field v-model="user.gender" label="Gender"></v-text-field>
                      </v-flex>
                       <v-flex xs12 sm6 md6 pl-4>
                        <v-text-field v-model="user.dob" label="DOB"></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field v-model="user.email" label="Email"></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-text-field v-model="user.phone" label="Phone" ></v-text-field>
                      </v-flex>
                      
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false">
                   Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="edit()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
           
          </v-flex>
        </v-layout> -->
        <!------------------------------------------------------------>
        <v-layout wrap>
          <v-flex xs12 class="text-left pt-4">
            <span class="nsbold" style="font-weight: 600; font-size: 24px"
              >Garments Details</span
            >
            <v-card round style="background-color: #766bc0">
              <v-layout wrap pa-2 style="color: #ffffff">
                <v-flex xs1>Id</v-flex>
                <v-flex xs3>Item</v-flex>
                <!-- <v-flex xs2>Preferences</v-flex> -->
                <v-flex xs5>Barcode Id</v-flex>
                <!-- <v-flex xs3>Fabric</v-flex> -->
                <!-- <v-flex xs2>Remark</v-flex> -->
                <!-- <v-flex xs3>Status</v-flex> -->
                <!-- <v-flex xs3 text-center>Delete</v-flex> -->
              </v-layout>
            </v-card>
            <v-card v-for="(item, i) in cloth" :key="i" elevation="0">
              <v-layout wrap pa-2>
                <v-flex xs1>{{ i + 1 }}</v-flex>
                <v-flex xs3 v-if="item.clothType">{{
                  item.clothType.name
                }}</v-flex>
                <!-- <v-flex xs2>{{item.preferences}}</v-flex> -->
                <v-flex xs6>{{ item.clothbarid }}</v-flex>
                <!-- <v-flex xs3>{{item.clothType}}</v-flex> -->
                <!-- <v-flex xs2>{{item.Remark}}</v-flex> -->
                <!-- <v-flex xs3>{{item.status}}</v-flex> -->

                <v-flex xs1 text-center>
                  <router-link
                    :to="'/clothHistory?id=' + item._id"
                    style="text-decoration: none"
                  >
                    <v-icon style="cursor: pointer; color: #766bc0"
                      >mdi-eye</v-icon
                    ></router-link
                  >
                </v-flex>
                <v-flex xs1 text-center>
                  <v-icon
                    style="cursor: pointer"
                    @click="(deletedialogue = true), (curId = item._id)"
                    >mdi-delete-outline</v-icon
                  >
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-flex xs12 text-left pt-4>
              <span class="nsbold" style="font-weight: 600; font-size: 24px"
                >Laundry In-Items</span
              >
            </v-flex>
            <v-flex xs12 class="text-left" v-if="stlaundry">
              <v-card round style="background-color: #766bc0">
                <v-layout wrap pa-2 style="color: #ffffff">
                  <v-flex xs4>Category</v-flex>
                  <v-flex xs4>Barcode Id</v-flex>
                  <v-flex xs4>Last In</v-flex>
                </v-layout>
              </v-card>
              <v-card elevation="0">
                <v-layout wrap pa-2 v-for="(item, i) in stlaundry" :key="i">
                  <v-flex xs4>
                    <span v-if="item.clothid">
                      <span v-if="item.clothid.clothType">
                        {{ item.clothid.clothType.name }}
                      </span>
                      <span v-else> - </span>
                    </span>
                    <span v-else> - </span>
                  </v-flex>
                  <v-flex xs4 v-if="item.clothid">{{
                    item.clothid.clothbarid
                  }}</v-flex>
                  <v-flex xs4 v-if="item.clothid">{{
                    formatDate(item.INdate)
                  }}</v-flex>

                  <v-flex xs12 py-1>
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 align-self-center text-center pa-4 v-else>
              <span class="nsregular">Currently no item found!</span>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-card>
      <v-dialog width="400px" v-model="deletedialogue">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Confirm Delete </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4 black--text"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="deletedialogue = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="snackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>
<script>
// import deleteStudent from "../views/deleteStudent";
// import Deletecloth from "./views/laundry/deletecloth";
import axios from "axios";
export default {
  components: {
    // Deletecloth,
  },
  data() {
    return {
      user: [],
      user1: null,
      cloth: [],
      admissionNo: null,
      appLoading: false,
      ServerError: false,
      name: null,
      Class: null,
      division: null,
      dialog: false,
      deletedialogue: false,
      curId: null,
      student: [], //incoming data frm backend
      stdata: [],
      clothes: null,
      snackbar: false,
      msg: null,
      stlaundry: null,
    };
  },
  mounted() {
    this.getData();
    // this.getlaundry();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/student/cloth/get",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.user = response.data.data;
            this.user1 = response.data.studentdetails;
            this.admissionNo = response.data.studentdetails.admissionNo;
            this.name = response.data.studentdetails.name;
            // this.lname = response.data.data[0].studentid.lname;
            this.Class = response.data.studentdetails.Class;
            this.division = response.data.studentdetails.division;
            this.cloth = response.data.data;

            if (this.cloth.length > 0) {
              this.getlaundry(this.cloth[0].clothbarid);
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getlaundry(myqr) {
      this.appLoading = true;
      // if (this.Qrcode != null) {
      //   this.status = this.curStatus;
      // }

      // var dyurl = null;
      // this.status = this.curStatus;
      // if (this.status == "IN") dyurl = "/cloth/washing/in";
      // else dyurl = "/cloth/washing/out";
      axios({
        method: "post",
        url: "/student/clothstatus/in",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          QR: myqr,
          // QR: this.Qrcode,
          presentStatus: "IN",
          studentid: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.stlaundry = response.data.data;
          } else {
            // this.msg = response.data.msg;
            // this.snackbar = true;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/remove/cloth",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.deletedialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            // this.$router.go(-1)
            this.snackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    show() {
      this.content = this.user;
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    getCloth(value) {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/per/cloth/history",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          clothid: value,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.cloth = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    edit() {
      this.user.id = this.$route.query.id;
      axios({
        method: "post",
        url: "/student/clothdetails/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.user,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.user = response.data.data;
          }
          this.getData();
        })
        .catch((err) => {
          console.log(err());
        });
    },
  },
};
</script>
<style scoped>
.gradientCard {
  background: transparent
    linear-gradient(104deg, #434343 0%, #484848 52%, #2c2b2b 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
}
</style>